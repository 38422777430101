$(() => {
    let timer: any;

    let subscriber = "client2.index";
    var $loadingContainer = $('#loadingContainer');
    var $partialContainer = $("#partialContainer");

    $loadingContainer.hide();
    $partialContainer.after($loadingContainer[0]);

    $(document).ajaxStart(() => {
        messenger.send(subscriber, {
            action: 'showLoading', setFocus: "#loading-msg"
        });
    });
    $(document).ajaxComplete(() => {
    });
});